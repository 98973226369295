import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

interface PropsType {
  title: ReactNode;
  subTitle?: ReactNode;
  icon?: ReactNode;
  children?: ReactNode;
}

const Result: FC<PropsType> = ({ title, subTitle, icon, children }) => {
  return (
    <ResultWrapper>
      {icon && <Icon>{icon}</Icon>}
      <Title>{title}</Title>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      <Extra>{children}</Extra>
    </ResultWrapper>
  );
};

export default Result;

const ResultWrapper = styled.div`
  ${tw`py-12`}
`;

const Icon = styled.div`
  ${tw`text-center m-auto`}
  width: 250px;
  height: 295px;
`;

const Title = styled.div`
  ${tw`text-4xl text-center mt-8 mb-4`}
`;

const SubTitle = styled.div`
  ${tw`text-2xl text-center text-gray-600 dark:text-gray-400`}
`;

const Extra = styled.div`
  ${tw`text-center mt-8`}
`;
