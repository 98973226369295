import React, { FC } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import SEO from 'src/components/organisms/common/SEO';
import NotFoundSvg from 'src/components/molecules/error/NotFoundSvg';
import Result from 'src/components/organisms/common/Result';
import { moveTo } from 'src/utils/route';
import Layout from 'src/components/templates/layout/Layout';

interface PropsType {}

const NotFoundTemplate: FC<PropsType> = () => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Result
        title={<span>404</span>}
        subTitle={<span>Sorry, the page you visited does not exist.</span>}
        icon={<NotFoundSvg />}
      >
        <Button onClick={() => moveTo('/')}>Back Home</Button>
      </Result>
    </Layout>
  );
};

export default NotFoundTemplate;

const Button = styled.button`
  ${tw`px-4 py-2 rounded-md text-sm font-medium border-0 focus:outline-none focus:ring transition text-white bg-red-500 hover:bg-red-600 active:bg-red-700 focus:ring-red-300`}
`;
